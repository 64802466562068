import React from "react"
import { Row } from "react-bootstrap"

const Availability = props => (
  <>
    <Row className="mb-5">
      <h4>
        {props.category} ON RENT | {props.category} RENTAL SERVICES IN GURGAON &
        DELHI NCR
      </h4>
      <p className="lead mt-3">
        Lappy Nation is one of the developing Information Technology Company,
        giving a wide scope of administrations from Students to Business, from
        Scholars to professionals both for huge and little ventures and public
        area endeavors. We offer rentals on various IT products such as Laptops
        etc. We offer our services in Gurgaon as well in Delhi NCR covering
        Gurugram, Faridabad,Noida.
      </p>
    </Row>
  </>
)

export default Availability
