import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"
import { Row } from "react-bootstrap"
import Product from "../components/product"
import { graphql } from "gatsby"
import Availability from "../components/availability"

const Laptops = ({ data }) => (
  <Layout>
    <Seo title="Laptop on rent" />
    <Banner title="Laptop on rent" />
    <section id="services" className="features-area pt-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <Row>
              {data.allLaptopsJson.edges.map(laptop => (
                <Product
                  key={laptop.node.id}
                  image={laptop.node.image}
                  name={laptop.node.name}
                  processor={laptop.node.processor}
                  memory={laptop.node.memory}
                  storage={laptop.node.storage}
                  col={4}
                />
              ))}
            </Row>
            <Availability category="LAPTOP" />
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export const query = graphql`
  query LaptopQuery {
    allLaptopsJson {
      edges {
        node {
          id
          image
          memory
          name
          processor
          storage
        }
      }
    }
  }
`

export default Laptops
